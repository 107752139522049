import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { auth,store } from '../authentication/Firebase';
import { doc, getDoc } from 'firebase/firestore';
import { userStorage } from '../constants';

const User = ({ theme, mode }) => {

  useEffect(() => {
    
  }, [mode]);

  theme.palette.mode = mode;
  const user = auth.currentUser;
  const [firstname, setFirstname] = React.useState("Please");
  const [lastname, setLastname] = React.useState("Wait...");
  const [organization, setOrganization] = React.useState("");
 useEffect(() => {
  if (user) {
    const docRef = doc(store,userStorage.users, user.uid); 
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        setFirstname(data.firstname);
        setLastname(data.lastname);
        setOrganization(data.organization);
      }
    });
  }
  }, []);

  // const avatarUrl = '';

  const textColor = theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000';

  //capitalize firstname first letter
   const capitalize = (s) => {
     if (typeof s !== 'string') return '';
     return s.charAt(0).toUpperCase() + s.slice(1);
   };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* <Avatar alt={firstname} src={avatarUrl} /> */}
      <Typography
        variant="body1"
        style={{
          marginLeft: 15,
          fontFamily: 'Audiowide, sans-serif',
          color: textColor
        }}
      >
        {capitalize(firstname)} . {capitalize(lastname)} {(organization !== "undefined" && organization !== "null" && organization) ? "@ " + organization : ""}
      </Typography>

      {/* <KeyboardDoubleArrowRightIcon style={{ marginLeft: 8 }} /> */}
    </div>
  );
};

export default User;
