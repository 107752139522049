import { auth, storage } from "../authentication/Firebase";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import axios from "axios";
async function loadImage(
  selectedRef,
  setConfirmDialogText,
  confirm,
  setEdit,
  setMetadataOpen,
  setMetaDataRef,
  setData,
  setMessage,
  handleSnackClick,
  setOpen,
  sceneRef,
  skyRef,
  edit,
  setIsLoading,
  setInHome
) {
  if (edit) {
    setConfirmDialogText(
      "Unsaved chnages are lost.Are you sure you want to change the image?"
    );
    const result = await confirm();
    if (!result) {
      return;
    }
  }
  setEdit(false);
  setMetadataOpen(false);
  Array.prototype.slice
      .call(document.querySelectorAll("[normal-to-camera]"))
      .forEach(function (item) {
        item.remove();
      });
  setIsLoading(true);
  setMessage("Loading Image!");
  handleSnackClick();
  getDownloadURL(ref(storage, selectedRef + ".png"))
    .then(function (url) {
      const img = new Image();
      img.src = url;
      img.onload = function () {
        const newSky = document.createElement("a-sky");
        newSky.setAttribute("class", "clickable");
        newSky.setAttribute("src", url);
        newSky.setAttribute("rotation", "0 -90 0");
        // Replace the existing <a-sky> element with the new one
        sceneRef.current.replaceChild(newSky, skyRef.current);
        skyRef.current = newSky;
        const metaDataPath = selectedRef + "-metadata.json";
        setMetaDataRef(metaDataPath);
        getDownloadURL(ref(storage, metaDataPath))
          .then((url) => {
            // Use the URL to fetch the JSON data
            return fetch(url);
          })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((jsonData) => {
            setInHome(false);
            setMessage("Image loaded!");
            handleSnackClick();
            setOpen(false);
            setData(jsonData);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error(
              "There was a problem with your fetch operation:",
              error
            );
            setMessage("No Image Found");
            handleSnackClick();
            setOpen(false);
            const newSky = document.createElement("a-sky");
            newSky.setAttribute("class", "clickable");
            newSky.setAttribute("src", "/interia_lobby.png");
            newSky.setAttribute("rotation", "0 -90 0");

            // Replace the sky with a default image on error
            sceneRef.current.replaceChild(newSky, skyRef.current);
            skyRef.current = newSky;
            setIsLoading(false);
          });
      };
    })
    .catch(function (error) {
      // Handle any errors
      console.error("Error fetching file:", error);
    });
}

// Fetch the public IP address
const fetchPublicIpAddress = async (setPublicIP) => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    setPublicIP(response.data.ip);
  } catch (error) {
    console.error("Failed to fetch public IP address:", error);
  }
};

// Fetch the private IP address
const fetchPrivateIpAddress = (setPrivateIP) => {
  const rtc = new RTCPeerConnection({ iceServers: [] });
  rtc.createDataChannel("");
  rtc.createOffer().then((offer) => rtc.setLocalDescription(offer));
  rtc.onicecandidate = (event) => {
    if (event && event.candidate && event.candidate.candidate) {
      const candidate = event.candidate.candidate;
      const uniqueAdress = candidate.split(" ")[4];
      if (uniqueAdress) {
        setPrivateIP(uniqueAdress);
        rtc.close();
      }
    }
  };
};

const setMultipleAttributes = (el, attributes) => {
  for (var attr in attributes) el.setAttribute(attr, attributes[attr]);
};
function createSpheres(jsonData) {




  const camera = document.querySelector("[camera]");
  const cameraPosition = new window.THREE.Vector3();
  camera.object3D.getWorldPosition(cameraPosition);

  for (var key in jsonData) {
    if (isNaN(key)) {
      continue;
    }

    var planeEl = document.createElement("a-plane");
    var borderCircleEl = document.createElement("a-ring");
    var circleEl = document.createElement("a-circle");
    var textEl = document.createElement("a-text");

    if (planeEl && borderCircleEl && circleEl && textEl) {
      // Set attributes for the sphere
      borderCircleEl.setAttribute("radius-inner", "10"); // Inner radius of the ring (hole size)
      borderCircleEl.setAttribute("radius-outer", "12"); // Outer radius of the ring (overall size)
      borderCircleEl.setAttribute("color", "blue");
      borderCircleEl.setAttribute(
        "material",
        "opacity: 0.8; transparent: true"
      );

      setMultipleAttributes(circleEl, {
        radius: 10,
        class: "clickable",
        color: "black",
        spheres: true,
        number: key,
        opacity: 0.5,
      });

      const intersection = jsonData[key].intersection; // This should be [x, y, z] format

      planeEl.setAttribute(
        "position",
        `${intersection.x} ${intersection.y} ${intersection.z}`
      );
      planeEl.setAttribute("normal-to-camera", true);
      planeEl.setAttribute("number", key );

      // Set attributes for the text (sphere number)
      setMultipleAttributes(textEl, {
        value: key, // Display the sphere number
        align: "center",
        color: "white",
        width: 300, // Adjust the size of the text to a smaller value for better visibility
        class: "metadata",
      });

      document.getElementsByTagName("a-scene")[0].appendChild(planeEl);
      planeEl.appendChild(borderCircleEl);
      planeEl.appendChild(circleEl);
      planeEl.appendChild(textEl);
    }
  }

  // Register the 'spheres' component if not already registered
  if (!window.AFRAME.components["spheres"]) {
    window.AFRAME.registerComponent("spheres", {
      init: function () {
        this.el.addEventListener("click", (e) => {
          const intersectedEl = e.target;
          const sphereNumber = intersectedEl.getAttribute("number");
        });
        this.el.addEventListener("mouseenter", (e) => {
          const intersectedEl = e.target;
          intersectedEl.setAttribute("color", "red");
        });
        this.el.addEventListener("mouseleave", (e) => {
          const intersectedEl = e.target;
          intersectedEl.setAttribute("color", "black");
        });
      },
    });
  }

  if (!window.AFRAME.components["normal-to-camera"]) {
    window.AFRAME.registerComponent("normal-to-camera", {
      tick: function () {
        const camera = this.el.sceneEl.camera; // Get the camera's object3D
        const plane = this.el.object3D; // Get the plane's object3D

        // Get the camera's forward direction (z-axis in three.js)
        const cameraDirection = new window.THREE.Vector3();
        camera.getWorldDirection(cameraDirection);

        // Position the plane to be normal to the camera’s view direction
        const targetPoint = plane.position.clone().sub(cameraDirection);
        plane.lookAt(targetPoint);

        const cameraPosition = new window.THREE.Vector3();
        camera.getWorldPosition(cameraPosition);

        const radialDistance = plane.position
          .clone()
          .sub(cameraPosition)
          .length();
        const perpendicularDistance = plane.position
          .clone()
          .sub(cameraPosition)
          .dot(cameraDirection);

        const scale = Math.abs(perpendicularDistance / radialDistance);
        plane.scale.set(scale, scale, scale);
      },
    });
  }
}
export default {
  loadImage,
  fetchPublicIpAddress,
  fetchPrivateIpAddress,
  createSpheres,
};
