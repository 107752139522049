import React, { useEffect, useState } from 'react';

const StereoScene = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (!scriptLoaded) {
      const script = document.createElement('script');
      script.src = '/stereoscopic_rendering.js'; 
      script.async = true;
      script.onload = () => {
        setScriptLoaded(true); 
      };
      script.onerror = (error) => {
        console.error("Error loading script:", error);
      };
      document.head.appendChild(script);
    }

    return () => {
      const existingScript = document.querySelector('script[src="/build.js"]');
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, [scriptLoaded]);

  if (!scriptLoaded) {
    return <div>Loading...</div>; 
  }

  return (
    <a-scene antialias="true">
      <a-assets>
        <img id="left" src="360pano_Left.jpg" />
        <img id="right" src="360pano_Right.jpg" />
      </a-assets>
      <a-entity
        camera
        look-controls
        position="0 0 0"
        stereocam="eye:left"
      ></a-entity>
      <a-sky id="sky1" src="#left" stereo="eye:left"></a-sky>
      <a-sky id="sky2" src="#right" stereo="eye:right"></a-sky>
      <a-vr-mode-ui>  <style>
          {`
              .a-enter-vr-button {
                height: 75px !important;
                width: 75px !important;
                background-color: transparent;
                position: fixed;
                bottom: 0vh;
                left: 50%;
                transform: translateX(-50%);
              }
            `}
        </style></a-vr-mode-ui>
    </a-scene>
  );
};

export default StereoScene;
