import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Paper,
  Stack,
  Avatar,
} from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import Drawer from "@mui/material/Drawer";
import { AppContext } from "../../AppContext.js";

const drawerWidth = 400;

const CommentBar = () => {
  const [messages, setMessages] = useState([
    {
      id: 1733312603849,
      text: "Chnage the color of the carpet to beige",
      replyTo: null,
      sender: "customer", // 'user' for self, 'other' for others
    },
  ]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [replyTo, setReplyTo] = useState(null);
  const { showComments, currentProjectName, currentRoomName, currentViewName } =
    useContext(AppContext);

  const handleSendMessage = () => {
    if (currentMessage.trim()) {
      const newMessage = {
        id: Date.now(),
        text: currentMessage,
        replyTo,
        sender: "user",
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setCurrentMessage("");
      setReplyTo(null);
    }
  };

  const handleReply = (message) => {
    setReplyTo(message);
  };

  return (
    <Drawer
      sx={(theme) => ({
        ...(showComments && {
          bgcolor:
            theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)",
          backdropFilter: "blur(24px)",
          marginLeft: "10px",
          border: "1px solid",
          borderColor: "divider",
          boxShadow:
            theme.palette.mode === "light"
              ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
              : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
          borderRadius: "10px",
        }),
        ...(!showComments && {
          display: "none",
        }),
        zIndex: 1102,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          ...(showComments && {
            position: "relative",
            width: drawerWidth,
            boxSizing: "border-box",
            height: "60vh",
            alignSelf: "center",
            bgcolor: "rgb(0,0,0,0)",
            borderRadius: "10px",
            scrollbarWidth: "none",
            scrollbarColor: "transparent transparent",
            padding: "0px",
            // color:
            //   theme.palette.mode === "dark"
            //     ? "#000"
            //     : theme.palette.primary.main,
          }),
          ...(!showComments && {
            display: "none",
          }),
        },
        height: "60vh",
        position: "absolute",
        top: "50%",
        right: "10px",
        transform: "translate(0, -50%)",
      })}
      variant="persistent"
      anchor="right"
      open={showComments}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        {currentProjectName && currentRoomName && (
          <Typography variant="h6" textAlign={"center"} sx={{ padding: 2 }}>
            {`${currentProjectName} . ${currentRoomName} . ${currentViewName}`}
          </Typography>
        )}
        <Box
          sx={{
            flex: 1,
            padding: 2,
            overflowY: "auto",
            maxHeight: "70vh",
          }}
        >
          {messages.map((message) => (
            <Box
              key={message.id}
              sx={{
                display: "flex",
                flexDirection:
                  message.sender === "user" ? "row-reverse" : "row",
                alignItems: "flex-start",
                marginBottom: 2,
              }}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: "8px", // Square corners
                }}
              >
                {message.sender === "user" ? "U" : "O"}
              </Avatar>
              <Paper
                sx={{
                  marginLeft: message.sender === "user" ? 0 : 2,
                  marginRight: message.sender === "user" ? 2 : 0,
                  padding: 1,
                  flexGrow: 1,
                }}
              >
                {message.replyTo && (
                  <Box
                  // sx={{
                  //   marginBottom: 1,
                  //   padding: 1,
                  //   borderLeft: "4px solid #1976d2",
                  // }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      Replying to: {message.replyTo.text}
                    </Typography>
                  </Box>
                )}
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Typography>{message.text}</Typography>
                  <IconButton size="small" onClick={() => handleReply(message)}>
                    <ReplyIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </Paper>
            </Box>
          ))}
        </Box>
        {replyTo && (
          <Box
            sx={{
              padding: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Replying to: {replyTo.text}
            </Typography>
            <Button
              size="small"
              onClick={() => setReplyTo(null)}
              sx={{ ml: 2 }}
            >
              Cancel
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: 1,
          }}
        >
          <TextField
            fullWidth
            value={currentMessage}
            onChange={(e) => setCurrentMessage(e.target.value)}
            placeholder="Type a message..."
            variant="outlined"
            size="small"
            sx={{ marginRight: 1 }}
          />
          <Button
            // variant="contained"
            color="primary"
            onClick={handleSendMessage}
          >
            <SendIcon fontSize="large" />
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CommentBar;
