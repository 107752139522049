import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { Stack, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import DoneIcon from '@mui/icons-material/Done';

export default function ThumbnailPopper({
  data,
  categories,
  projID,
  roomID,
  viewID,
  submitFunction,
  anchorEl,
  setAnchorEl,
  type,
  isThumbnail,
  currentThumbnail,
  theme = null,
}) {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const [orderedData, setOrderedData] = React.useState([]);
  const [displayData, setDisplayData] = React.useState([]);
  const [category, setCategory] = React.useState("All");
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [searchTerm, setSearchTerm] = React.useState("");

  //match the current thumbnail name in data
  React.useEffect(() => {
    if (isThumbnail && currentThumbnail) {

      const currentThumbnailIcon = currentThumbnail.icon;
      

      const matchedItem = data.find(
        (item) => item.icon === currentThumbnailIcon
      );
      //now give the matched item ,id number 1 and other items sequential id starting with 2
      const newData = data.map((item) => {
        if (item.icon === matchedItem.icon) {
          return { ...item, id: 1 };
        }
        return { ...item, id: data.indexOf(item) + 2 };
      });
      setOrderedData(newData);
      setDisplayData(newData);
    } else {
      const newData = data.map((item, index) => ({ ...item, id: index + 1 }));
      
      setOrderedData(newData);
      setDisplayData(newData);
    }
  }, [anchorEl, projID, roomID, viewID]);

  // Function to filter data based on category and search term
  const filterData = (filter) => {
    let filteredData = orderedData;

    // Filter by category
    if (filter !== "All") {
      filteredData = filteredData.filter((item) => item.category === filter);
    }

    // Filter by search term
    if (searchTerm) {
      filteredData = filteredData.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setDisplayData(filteredData);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    filterData(category); // Filter based on the selected category
  };

  const handleDoubleClick = (item) => {
    submitFunction(projID, roomID, viewID, selectedItem, type);
    setAnchorEl(null);
    setSelectedItem(null);
  };

  return (
    <div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 1201 }}
        placement="right-start"
      >
        <Box
          sx={{
            p: 1,
            borderRadius: 2,
            maxHeight: "60vh",
            bgcolor: theme?.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(24px)",
            marginLeft: "10px",
            border: "1px solid",
            borderColor: "divider",
            boxShadow: theme?.palette.mode === "light"
              ? "0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)"
              : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            overflowY: "auto",
            msOverflowStyle: "none", // IE and Edge
            scrollbarWidth: "none", // Firefox
            WebkitOverflowScrolling: "touch", // Smooth scrolling for iOS
          }}
        >

          <CloseIcon
            fontSize="small"
            onClick={() => {
              setAnchorEl(null);
              setSelectedItem(null); // Clear the selected item on close
            }}
            style={{
              position: "absolute",
              left: "15px",
              top: "15px",
              cursor: "pointer",
            }}
          />
          <Stack spacing={3}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
              spacing={2}
            >
              <TextField
                variant="standard"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                aria-label="Search"
              />
              <TextField
                select
                label="" // Disable the floating label
                value={category}
                onChange={(e) => {
                  const selectedCategory = e.target.value;
                  setCategory(selectedCategory);
                  filterData(selectedCategory); // Filter based on the selected category
                }}
                size="small"
                InputProps={{
                  startAdornment: (
                    <span style={{ marginRight: '5px', fontSize: '0.875rem',
                      color:
                      theme.palette.mode === "light"
                        ? "rgba(0, 0, 0, 0.87)"
                        : "rgba(255, 255, 255, 0.87)" }}>
                      Category:
                    </span>
                  ),
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        bgcolor: 
                          theme?.palette.mode === "light"
                            ? "rgba(255, 255, 255, 0.4)"
                            : "rgba(0, 0, 0, 0.4)", // Background color based on theme mode
                        color:
                          theme.palette.mode === "light"
                            ? "rgba(0, 0, 0, 0.87)"
                            : "rgba(255, 255, 255, 0.87)",
                        backdropFilter: "blur(10px)", // Optional blur effect
                      },
                    },
                  },
                }}
              >
                {categories.map((category) => (
                  <MenuItem
                    key={category}
                    value={category}
                    sx={{
                      "&:hover": {
                        bgcolor: theme?.palette.mode === "light"
                          ? "rgba(240, 240, 240, 0.5)"
                          : "rgba(60, 60, 60, 0.5)", // Hover color
                      },
                    }}
                  >
                    {category}
                  </MenuItem>
                ))}
              </TextField>


            </Stack>
            <Grid
              container
              width={"60vw"}
              overflow={"scroll"}
              height={"30vh"}
              sx={{
                msOverflowStyle: "none",
                scrollbarWidth: "none",
                WebkitOverflowScrolling: "touch",
                userSelect: "none",
              }}
              rowGap={2}
              rowSpacing={2}
              columnGap={2}
              justifyContent={"center"}
              alignItems={"flex-start"}
              columns={{ xs: 15 }}
            >
              {/* Render the currentThumbnail first, if available */}
              {isThumbnail && (
                <Grid
                  item
                  xs={3}
                  key={currentThumbnail.id}
                  sx={{
                    "&:hover": {
                      transform: "scale(1.05)",
                      transition: "transform 0.2s",
                      backgroundColor: "rgba(2, 31, 59, 0.65)",
                    },
                    borderRadius: "5px",
                    border: "1px solid",
                    position: "relative",
                  }}
                  onClick={() => {
                    setSelectedItem(currentThumbnail);
                  }}
                  onDoubleClick={(e) => { e.preventDefault(); handleDoubleClick(currentThumbnail) }}
                  backgroundColor={"rgba(2, 31, 59, 0.65)"}
                >
                  <Tooltip title="Remove Current Thumbnail">
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        submitFunction(projID, roomID, viewID, {}, type);
                        setSelectedItem(null);
                      }}
                      style={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                      }}
                    >
                      <CloseIcon sx={{ color: "red" }} fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Stack
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    spacing={2}
                  >
                    <img
                      src={currentThumbnail.icon}
                      alt={currentThumbnail.name}
                      style={{ width: "50px", height: "50px" }}
                    />
                    <p style={{ textAlign: "center" }}>
                      {currentThumbnail.name}
                    </p>
                  </Stack>
                </Grid>
              )}

              {/* Render the rest of the displayData */}
              {displayData
                .filter((item) => item.icon !== currentThumbnail?.icon)
                .map((item) => (
                  <Grid
                    item
                    xs={3}
                    key={item.id}
                    sx={{
                      "&:hover": {
                        transform: "scale(1.05)",
                        transition: "transform 0.2s",
                        backgroundColor: "rgba(2, 31, 59, 0.65)",
                      },
                      borderRadius: "5px",
                      border: "1px solid",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedItem(item);
                    }}
                    onDoubleClick={(e) => { e.preventDefault(); handleDoubleClick(item) }}
                    backgroundColor={selectedItem === item ? "rgba(2, 31, 59, 0.65)" : ""}
                  >
                    <Stack
                      direction={"column"}
                      alignItems={"center"}
                      justifyContent={"space-evenly"}
                      spacing={2}
                    >
                      <img
                        src={item.icon}
                        alt={item.name}
                        style={{ width: "50px", height: "50px" }}
                      />
                      <p style={{ textAlign: "center" }}>{item.name}</p>
                    </Stack>
                  </Grid>
                ))}
            </Grid>
          </Stack>
        </Box>
      </Popper>
    </div>
  );
}
