import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import InteriaLogo from '../../icons/interia_logo.svg';
import InteriaImage from '../../icons/interia_image.jpg';
import VRUserImage from '../../icons/vr_user_home_1.jpg';
import VRVoiceImage from '../../icons/vr_user_home_voice.jpg';
import MetaDataImage from '../../icons/meta_data.png';
import CompassDisplayImage from '../../icons/compass_display.png';
import CommentsImage from '../../icons/comments.png';
import VRHeadsetImage from '../../icons/vr-headset.svg';
import VRHeadsetIsoImage from '../../icons/vr_headset_iso.png';
import VRHeadsetSpecsImage from '../../icons/vr_headset_specs.png';
import VRImmersiveRoomImage from '../../icons/vr_immersive_room.jpg';
import DesktopImage from '../../icons/desktop.svg';
import TabletImage from '../../icons/tablet.svg';
import SupportBugImage from '../../icons/support_bug.png';
import SupportTypesImage from '../../icons/support_types.png';

const items = [
  {
    icon: InteriaLogo,
    title: 'Immersive 360 Views - Panorama and 3D',
    description:
      'View 360 views of your rooms on the Meta Quest VR Headset or Interia Headsets (Phone driven)',
    lightImages: [`url(${VRImmersiveRoomImage})`],
    darkImages: [`url(${VRImmersiveRoomImage})`],
  },
  {
    icon: InteriaLogo,
    title: 'Author Meta Data Into Views',
    description:
      'Overlay useful information about designs into the 360 Views. The information could include name-value pairs, comments, floor plan, compass etc.',
      lightImages: [`url(${MetaDataImage})`, `url(${CompassDisplayImage})`],
      darkImages: [`url(${MetaDataImage})`, `url(${CompassDisplayImage})`],
  },
  {
    icon: InteriaLogo,
    title: 'Experience the Project on Desktop or VR Headsets',
    description:
      'Customers can view the designs on the desktop or using VR headsets for an immersive experience.',
      lightImages: [`url(${VRHeadsetImage})`, `url(${DesktopImage})`, `url(${TabletImage})`,],
      darkImages: [`url(${VRHeadsetImage})`,`url(${DesktopImage})`, `url(${TabletImage})`, ],
  },
  {
    icon: InteriaLogo,
    title: 'Voice Driven Navigation in VR',
    description:
      'Navigate different rooms and look up information using voice commands',
      lightImages: [`url(${VRVoiceImage})`],
      darkImages: [`url(${VRVoiceImage})`],
  },
  {
    icon: InteriaLogo,
    title: 'Capture Voice and Written Feedback',
    description:
      'Designers and customers can exchange feedback on the designs. All captured and archived on the cloud.',
      lightImages: [`url(${CommentsImage})`, `url(${VRVoiceImage})`],
      darkImages: [`url(${CommentsImage})`, `url(${VRVoiceImage})`],
  },
  {
    icon: InteriaLogo,
    title: 'Free Phone Based VR Interia Headsets',
    description:
      'Subscription includes 1 or more free phone based VR headsets*',
      lightImages: [`url(${VRHeadsetIsoImage})`, `url(${VRHeadsetSpecsImage})`],
      darkImages: [`url(${VRHeadsetIsoImage})`, `url(${VRHeadsetSpecsImage})`],
  },
  {
    icon: InteriaLogo,
    title: 'Always on the Latest Version',
    description:
      'Everytime you log into the web app, you are on the latest version with the latest features and bug fixes. No time consuming updates.',
      lightImages: [`url(${VRUserImage})`],
      darkImages: [`url(${VRUserImage})`],
  },
  {
    icon: InteriaLogo,
    title: 'Support Portal',
    description:
      'Raise and track issues via the support portal. Be notified when your questions have been answered or your issues resolved.',
      lightImages: [`url(${SupportTypesImage})`, `url(${SupportBugImage})`],
      darkImages: [`url(${SupportTypesImage})`, `url(${SupportBugImage})`],
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const theme = useTheme(); 
  
  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 }, maxWidth: false }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              Features
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              Interia enables you to experience your dream home before you make it real
            </Typography>
          </div>
          
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor:
                    selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index
                            ? 'primary.main'
                            : 'grey.300';
                        }
                        return selectedItemIndex === index
                          ? 'primary.main'
                          : 'grey.700';
                      },
                    }}
                  >
                    <img
                      src={icon}
                      alt="logo of product"
                      width={'40px'}
                      height={'40px'}
                    />
                  </Box>
                  <div>
                    <Typography
                      color="primary.main"
                      variant="body2"
                      fontSize={"20px"}
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                    {/* <Link
                      href="/applications/interia/home/"
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > svg': { transition: '0.2s' },
                        '&:hover > svg': { transform: 'translateX(2px)' },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: '1px', ml: '2px' }}
                      />
                    </Link> */}
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
  item
  xs={12}
  md={6}
  sx={{
    display: { xs: 'none', sm: 'flex' },
    width: '100%',
    alignItems: 'center', // Vertically center the grid content
  }}
>
  <Stack
    direction="column"
    justifyContent="center"
    alignItems="flex-start"
    spacing={2}
    useFlexGap
    sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
  >
    {items[selectedItemIndex][theme.palette.mode === 'light' ? 'lightImages' : 'darkImages'].map((ithImage, index) => {
      return (
        <Card
          variant="outlined"
          sx={{
            height: '100%',
            width: '100%',
            display: { xs: 'none', sm: 'flex' },
            pointerEvents: 'none',
          }}
          key={index}
        >
          <Box
            sx={{
              m: 'auto',
              p: 1, // Padding around the image
              width: 300,
              height: 400,
              borderRadius: '8px', // Rounded corners
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundImage: ithImage,
            }}
          />
        </Card>
      );
    })}
  </Stack>
</Grid>

      </Grid>
    </Container>
  );
}
