import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import Homepage from "./components/homepage/Homepage";
import SignIn from "./components/signin/SignIn";
import ForgotPassword from "./components/signin/ForgotPwd.js";
import SignUp from "./components/signup/SignUp";
import InteriaUI from "./components/landingpage/InteriaUi";
import QuestPage from "./components/customerPage/customerPage.js";
import SignedInRoute from "./components/authentication/SignedInRoute";
import MobilePage from "./components/mobilePage/mobile";
import NotFoundPage from "./404.js";
import DesignerHelpPage from "./components/landingpage/Helpppage.js";
import CustomerHelpPage from "./components/mobilePage/Helpppage.js";
import KeyListener from "./components/testComponent.js";

import "./styles/App.css";
import ResetPassword from "./components/authentication/VerifyResetPassword.js";

function LoggedInUser() {
  const [isQuest, setIsQuest] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let details = navigator.userAgent;
    if (details.includes("OculusBrowser")) {
      setIsQuest(true);
      navigate("/customer/quest");
    }
  }, [navigate]);

  return (
    <div className="App">
      <center><h1>Welcome to Interia</h1></center>
    </div>
  );
}

function App() {

  const RedirectToExternal = () => {
    React.useEffect(() => {
      window.location.href = "https://multivrselab.atlassian.net/servicedesk/customer/portal/1/group/1/create/17";
    }, []);
    return null; // Render nothing since the user is being redirected
  };

  return (
    <Router>
      <Routes>

        <Route path="/user" element={<LoggedInUser />} />
        <Route path="/test" element={<KeyListener />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/signup" element={<RedirectToExternal />} />        
        <Route path="/designer-help" element={<DesignerHelpPage />} />
        <Route path="/customer-help" element={<CustomerHelpPage />} />
        <Route element={<SignedInRoute redirect="/signin" />}>
        <Route path="/designer" element={<InteriaUI />} />
        </Route>
        <Route path="/customer/quest" element={<QuestPage />} />
        <Route element={<SignedInRoute redirect="/signin" />}>
          <Route path="/customer" element={<MobilePage />} />
        </Route>
        {/* 404 page route */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

    </Router>
  );
}

export default App;
