import React, { useState, useEffect, useRef } from "react";
import { Box, Stack, Typography, Button, Tooltip } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MobileAppAppBar from "./mobileAppBar.js";
import getLPTheme from "../../getLPTheme.js";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { auth } from "../authentication/Firebase";
import { URL, MQTTOptions } from "../constants.js";
import mqtt from "mqtt";
import commonFunctions from "../CommonComponents/commonFunctions.js";
import QuestPage from "../customerPage/customerPage.js";
import CommentBar from "../landingpage/CommentBar";

const MobilePage = () => {
  const [connectStatus, setConnectStatus] = useState(false);
  const [token, setToken] = useState("");
  const [mode, setMode] = useState(() => {
    return localStorage.getItem("theme") || "dark";
  });
  const [isDesktop, setIsDesktop] = useState(false);
  const [activateCardboard, setActivateCardboard] = useState(false);



  //check if the device is a desktop baded on size of screen
  useEffect(() => {
    
    
    const checkScreenSize = () => {
      return window.innerWidth > 850 ? setIsDesktop(true) : setIsDesktop(false);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const LPtheme = createTheme(getLPTheme(mode));
  const [publicIP, setPublicIP] = useState("");
  const [privateIP, setPrivateIP] = useState("");
  const timeoutID = useRef(null);

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === "dark" ? "light" : "dark"));
  };

  useEffect(() => {
    localStorage.setItem("theme", mode);
  }, [mode]);

  useEffect(() => {
    commonFunctions.fetchPublicIpAddress(setPublicIP);
    commonFunctions.fetchPrivateIpAddress(setPrivateIP);
    generateToken();
  }, []);

  useEffect(() => {
    if (!publicIP || !privateIP) return;

    const newClient = mqtt.connect(URL.MQTTServer, MQTTOptions);
    const message = {
      token: token,
      username: auth.currentUser.email,
      privateIP: privateIP,
    };

    newClient.on("connect", () => {
      
      const wildcardTopic = `${publicIP}`; // Listen to all topics starting with public IP

      newClient.subscribe(wildcardTopic, { qos: 1 }, (err) => {
        if (err) {
          console.error(
            "Failed to subscribe to wildcard topic:",
            wildcardTopic,
            err
          );
        } else {
          
        }
      });
    });

    newClient.on("message", (topic, message) => {
      
      const data = JSON.parse(message.toString());
      if (topic === publicIP) {
        if (data.stop == privateIP) {
          setConnectStatus(true);
          
        }
      }
      if (data.message === "SimutopiaMobileReload") {
        window.location.reload();
      }
    });

    newClient.on("error", (err) => {
      console.error("Connection error:", err);
    });

    if (!connectStatus) {
      timeoutID.current = setInterval(() => {
        if (publicIP && privateIP && newClient) {
          
          newClient.publish(
            publicIP + "/" + privateIP,
            JSON.stringify(message),
            { qos: 1 },
            (err) => {
              if (err) {
                console.error("Failed to publish message", err);
              }
            }
          );
        }
      }, 5000);
    } else if (connectStatus) {
      
      clearInterval(timeoutID.current);
      newClient.subscribe(publicIP + "/" + privateIP, { qos: 1 }, (err) => {
        if (err) {
          console.error(
            "Failed to subscribe to topic:",
            publicIP + "/" + privateIP,
            err
          );
        } else {
          
        }
      });
    }

    return () => {
      if (timeoutID.current) {
        clearInterval(timeoutID.current);
      }
      if (newClient) {
        newClient.end();
      }
    };
  }, [publicIP, privateIP, connectStatus, token]);

  function generateToken() {
    const uid = auth.currentUser.uid;
    fetch(URL.authServer + "/generate-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid: uid }),
    })
      .then((res) => res.json())
      .then((data) => {
        setToken(data.token);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const handleActivateCardboard = () => {
    setActivateCardboard(!activateCardboard);
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <MobileAppAppBar
        theme={LPtheme}
        mode={mode}
        toggleColorMode={toggleColorMode}
        handleActivateCardboard={handleActivateCardboard}
        activateCardboard={activateCardboard}
      />
      {isDesktop ? (
        <QuestPage isDesktop={true} token={token} />
      ) : (
        activateCardboard ? (
          <QuestPage isDesktop={false} token={token} cardboardModeActive={true} handleActivateCardboard={handleActivateCardboard} activateCardboard={activateCardboard} />
        ) : (
          <Box
            sx={{
              flexGrow: 1,
              marginTop: "100px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                width: "100%",
              }}
            >
              <Tooltip title="Connection Status">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    borderRadius: "10px",
                    boxShadow:
                      LPtheme.palette.mode === "light"
                        ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                        : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
                  }}
                >
                  <Typography variant="h5">Connection Status:</Typography>
                  <Box
                    sx={{
                      backgroundColor: connectStatus ? "green" : "red",
                      height: "18px",
                      width: "18px",
                      borderRadius: "50%",
                      marginLeft: "10px",
                      marginTop: "2px",
                      display: "inline-block",
                    }}
                  />
                </Box>
              </Tooltip>
            </Box>

            <Stack
              spacing={3}
              sx={{
                fontSize: "large",
                alignItems: "center",
                justifyContent: "center",
                width: "100%", // Make it take full width for centering
                maxWidth: 480,
                marginTop: "20px", // Add some space between connection status and stack
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  width: "60%",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
                }}
                onClick={() => handleActivateCardboard()}
              >
                Enter Cardboard Mode
              </Button>

              <Paper
                elevation={3}
                sx={{
                  padding: "30px",
                  width: "100%",
                  borderRadius: "10px",
                  boxShadow:
                    LPtheme.palette.mode === "light"
                      ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                      : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  align="center"
                  sx={{
                    boxShadow:
                      LPtheme.palette.mode === "light"
                        ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                        : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
                  }}
                >
                  Instructions to Use the Quest
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    color: "#D6DCE1",
                    padding: "10px",
                    marginBottom: "15px",
                  }}
                >
                  1. Make sure the Quest and this device are on the same network.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    color: "#D6DCE1",
                    padding: "10px",
                    marginBottom: "15px",
                  }}
                >
                  2. Keep this tab active while wearing your Quest.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    color: "#D6DCE1",
                    padding: "10px",
                    marginBottom: "15px",
                  }}
                >
                  3. Click or say 'Yes' on the Quest to connect.
                </Typography>
                <Box
                  sx={{
                    marginTop: "10px",
                    borderTop: "1px solid #ddd",
                    paddingTop: "10px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontStyle: "italic",
                      color: "grey",
                      textAlign: "center",
                    }}
                  >
                    Click on the cardboard icon to view in cardboard mode.
                  </Typography>
                </Box>
              </Paper>
            </Stack>
          </Box>

        )
      )
      }
      {isDesktop&&(<CommentBar />)}
    </ThemeProvider>
  );
};

export default MobilePage;
